import React from 'react';
import whatsappIcon from '../assets/whatsapp-icon.png';  // Adjust the path if necessary

function Contact() {
    return (
        <div className="contact">
            Contáctanos al{"  "}
            <a href="https://wa.me/+5493424635689" target="_blank" rel="noopener noreferrer">
                342-4635-689{"  "}
                <img src={whatsappIcon} alt="WhatsApp Icon" className="whatsapp-icon" />
            </a>
            
        </div>
    );
}

export default Contact;