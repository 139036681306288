import React from 'react';

function Payment() {
    return (
        <div className="payment">
            Aceptamos Transferencias Bancarias y Mercado Pago
        </div>
    );
}

export default Payment;
