import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';  // Import Typography
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StorefrontIcon from '@mui/icons-material/Storefront';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';

function Services() {
    return (
        <div className="services">
            <List className="services-list">
                <ListItem>
                    <ListItemIcon>
                        <LocalShippingIcon />
                    </ListItemIcon>
                    <ListItemText primary={
                        <Typography variant="body1" style={{ fontSize: '1.5em' }}>Mudanzas</Typography>
                    } />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <StorefrontIcon />
                    </ListItemIcon>
                    <ListItemText primary={
                        <Typography variant="body1" style={{ fontSize: '1.5em' }}>Repartos</Typography>
                    } />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <TransferWithinAStationIcon />
                    </ListItemIcon>
                    <ListItemText primary={
                        <Typography variant="body1" style={{ fontSize: '1.5em' }}>Traslados</Typography>
                    } />
                </ListItem>
            </List>
        </div>
    );
}

export default Services;
