import React, { useEffect } from 'react';
import Header from './components/Header';
import Services from './components/Services';
import Contact from './components/Contact';
import Payment from './components/Payment';
import './App.css';  // Include this if you want to add styles in App.css

function App() {
  useEffect(() => {
    document.title = 'Fletes y Mudanzas Tucho';
  }, []);
    return (
        <div className="App">
            <Header />
            <Services />
            <Contact />
            <Payment />
        </div>
    );
}

export default App;
