import React from 'react';

function Header() {
    return (
        <div className="header">
            FLETES TUCHO
        </div>
    );
}

export default Header;
